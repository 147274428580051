module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-theme-event/gatsby-browser.js'),
      options: {"plugins":[],"rootPath":"/en","homePath":"/en","locale":"en","landingPageFilter":{"isPro":{"eq":true},"mainImage":{"url":{"ne":null}},"logo":{"url":{"ne":null}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-55197303-14","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**"],"cookieDomain":"directory.bottlebooks.me"},
    }]
