import {
  defaultLocale,
  load as loadBase,
} from '@bottlebooks/gatsby-theme-event/src/wrapElement/lingui/i18n.config';
import { i18n } from '@lingui/core';

export { locales } from '@bottlebooks/gatsby-theme-event/src/wrapElement/lingui/i18n.config';
export { defaultLocale };

export async function load(locale) {
  if (!locale) return {};
  const baseMessages = await loadBase(locale);
  const catalog = await import(
    /* webpackMode: "lazy", webpackChunkName: "i18n-[request]" */
    `../../../../locales/${locale}.js`
  );

  const messages = { ...baseMessages, ...catalog.messages };

  i18n.load(locale, messages);
  return messages;
}
